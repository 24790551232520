import Vue from 'vue'
import Vuex from 'vuex'

import User from '@/models/User'

export const LOGIN  = 'LOGIN';
export const LOGOUT = 'LOGOUT';

Vue.use(Vuex);

const state = {
    user: User.from(localStorage['mrel-token']),
    token: localStorage.getItem("mrel-token"),
    orgIndex: localStorage.getItem("oi") || 0,
    isLoggedIn: !!localStorage.getItem("mrel-token")
};

const mutations = {
    [LOGIN]: (state, data) => {
        state.token    = data.token;
        state.user     = User.from(data.token);
        state.orgIndex = 0;
        localStorage.setItem("mrel-token", data.token);
    },
    [LOGOUT]: (state) => {
        state.token = null;
        state.user  = null;
        localStorage.removeItem("mrel-token");
    }
};

const getters = {
    currentUser: state => {
        return state.user
    },
    currentOrg: state => {
        return state.user.orgs ? state.user.orgs[state.orgIndex] : []
    },
    isLoggedIn: state => {
        return state.isLoggedIn
    }
};

const actions = {
    login({commit}) {
        commit(LOGIN)
    },

    logout({commit}) {
        commit(LOGOUT)
    }
};

export default new Vuex.Store({
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions
})
