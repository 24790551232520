window.$ = window.jQuery = require('jquery');

import 'jquery';
import 'daterangepicker';
import 'metismenu';
import 'select2';

import Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from "moment";
import {BigNumber} from "bignumber.js";
import VeeValidate from 'vee-validate';
import VueToastr from 'vue-toastr';
import VueApexCharts from 'vue-apexcharts';

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import App from './App.vue';
import router from './router';
import store from './store';

/* Styles */
require("./assets/css/vue-multiselect.css");
require("./assets/css/vendor/jquery-jvectormap-1.2.2.css");
require("./assets/css/icons.min.css");
require("./assets/css/app-creative.min.css");


/* Scripts */
require("./assets/js/vendor");
require("./assets/js/app");
require("./assets/js/vendor/jquery-jvectormap-1.2.2.min");
require("./assets/js/vendor/jquery-jvectormap-world-mill-en");

/* Main */
require("./assets/js/pages/demo.dashboard");
require("./assets/js/pages/demo.form-wizard");


Vue.config.productionTip = false;

Vue.prototype.$API_URL = process.env.VUE_APP_API_URL;
Vue.prototype.$SITE_URI = process.env.VUE_APP_SITE_URI;
Vue.prototype.$BLOCK_EXPLORER = process.env.VUE_APP_BLOCK_EXPLORER;

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=no'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ]
};


Vue.use(VeeValidate);
Vue.use(VueToastr);

Vue.use(VueAxios, axios.create({
    baseURL: Vue.prototype.$API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage['mrel-token']
    }
}));

Vue.axios.interceptors.request.use((config) => {
    let token = localStorage.getItem("mrel-token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error)
});


Vue.axios.interceptors.response.use((res) => {
    return res;
}, function (error) {
    if (error.response && error.response.status === 401) {
        window.location.href = "/auth/login";
        console.log(error.response.status);
    }
    return Promise.reject(error)
});


Vue.filter('count', function (value) {
    if (value === undefined || value === null) return '';
    return new BigNumber(value).toString();
});
Vue.filter('unitFix', function (value, precision) {
    if (value === undefined || value === null) return '';
    return new BigNumber(value).toFixed(precision);
});
Vue.filter('fromNow', function (dateString) {
    if (!dateString) return '';
    return moment(dateString).fromNow();
});
Vue.filter('date', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMMM Do YYYY");
});
Vue.filter('time', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("hh:mm:ss a");
});
Vue.filter('timeline', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("DD/MM/YYYY");
});
Vue.filter('timestamp', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMMM Do YYYY, h:mm:ss a");
});
Vue.filter('long', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMM Do, h:mm a");
});
Vue.filter('shorttimestamp', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMM Do YYYY, h:mm a");
});
Vue.filter('epoch', function (epochString) {
    return moment.unix(epochString).format('MMM Do YYYY');
});
Vue.filter('medium', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMM Do YYYY");
});
Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});
Vue.filter('lowercase', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.toLowerCase();
});
Vue.filter('truncate', function (text, length, suffix) {
    if (text.length <= length) return text;
    return text.substring(0, length) + suffix;
});
Vue.filter('percentage', function (value, precision) {
    if (value === null || value === undefined) return 0;
    return value.toFixed(precision) + '%';
});

Vue.filter('value', function (obj, key) {
    if (key && obj) {
        return obj[key];
    } else {
        return '';
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app'); 
