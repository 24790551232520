import Vue from 'vue'
import VueRouter from 'vue-router'
import User from '@/models/User'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: resolve => require(["@/components/protected/Workspace.vue"], resolve),
            children: [
                {
                    path: "",
                    name: "dashboard",
                    component: resolve => require(["./components/protected/dashboard/Dashboard.vue"], resolve)
                },
                {
                    path: 'consignments',
                    component: resolve => require(["./components/protected/processes/ProcessesLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "consignments",
                            component: resolve => require(["./components/protected/processes/Processes.vue"], resolve)
                        },
                        {
                            path: ':consignmentid',
                            component: resolve => require(["./components/protected/processes/actions/ProcessActionLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    props: true,
                                    name: "steps",
                                    component: resolve => require(["./components/protected/processes/actions/Steps.vue"], resolve)
                                },
                                {
                                    path: "print",
                                    props: true,
                                    name: "print-consignment",
                                    component: resolve => require(["./components/protected/processes/actions/PrintConsignment.vue"], resolve)
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'batches',
                    component: resolve => require(["./components/protected/productions/ProductionLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "batches",
                            component: resolve => require(["./components/protected/productions/Productions.vue"], resolve)
                        },
                        {
                            path: "report",
                            props: true,
                            name: "batch-report",
                            component: resolve => require(["./components/protected/productions/actions/BatchReport"], resolve)
                        },
                        {
                            path: ':batchid',
                            component: resolve => require(["./components/protected/productions/actions/ProductionActionLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    props: true,
                                    name: "production-steps",
                                    component: resolve => require(["./components/protected/productions/actions/ProductionSteps"], resolve)
                                },
                                // {
                                //     path: "report",
                                //     props: true,
                                //     name: "production-report",
                                //     component: resolve => require(["./components/protected/productions/actions/BatchReport"], resolve)
                                // }
                            ]
                        }
                    ]
                },
                {
                    path: '/user',
                    component: resolve => require(["./components/protected/user/UserLayout"], resolve),
                    children: [
                        {
                            path: "profile",
                            name: "user-profile",
                            component: resolve => require(["./components/protected/user/Profile.vue"], resolve)
                        }
                    ]
                }
            ]
        },
        {
            path: '/auth',
            component: resolve => require(["@/components/auth/AuthLayout.vue"], resolve),
            children: [
                {
                    path: 'login',
                    name: "login",
                    meta: {
                        unsecured: true
                    },
                    component: resolve => require(["@/components/auth/Login.vue"], resolve)
                },
                {
                    path: 'signup',
                    name: "signup",
                    meta: {
                        unsecured: true
                    },
                    component: resolve => require(["@/components/auth/Signup.vue"], resolve)
                },
                {
                    path: 'changepassword',
                    name: "changepassword",
                    meta: {
                        unsecured: true
                    },
                    component: resolve => require(["@/components/auth/ChangePassword.vue"], resolve)
                },
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {
    if(!to.meta.unsecured) {
        let user = User.from(localStorage['mrel-token']);
        if(user) {
            next()
        } else {
            next({
                name: 'login'
            })
        }
    }
    next()
});

export default router;
