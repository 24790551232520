import JwtDecode from 'jwt-decode';

export default class User {
    constructor({user_id, email, first_name, last_name, did, roles, mill_id}) {
        this.id = user_id;
        this.email = email;
        this.firstName = first_name;
        this.lastName = last_name;
        this.name = this.firstName + " " + this.lastName;
        this.did = did;
        this.roles = roles;
        this.mill_id = mill_id;
    }

    isAdmin() {
        return this.roles.includes('Admin');
    }

    isAttester() {
        return this.roles.includes('Attester');
    }

    static from(token) {
        try {
            let obj = JwtDecode(token);
            return new User(obj);
        } catch (_) {
            return null;
        }
    }
}
